<template>
  <div class="olimp-admin-form create-refferal-modal">
    <a-form-model
      class="mb-1"
      ref="editModalForm"
      :model="form"
      :rules="rules"
      @submit="handleSubmit"
    >
      <a-row>
        <a-col :span="24" class="p-2">
          <a-form-model-item ref="name" prop="name" label="Bundle ID">
            <a-input v-model="form.bundle" placeholder="Bundle ID"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="24" class="p-2">
          <a-form-model-item ref="name" prop="name" label="Friendly name">
            <a-input v-model="form.name" placeholder="Name"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="24" class="p-2">
          <a-form-model-item ref="promocode" prop="promocode" label="Promocode">
            <a-input v-model="form.promocode" placeholder="Promocode"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <hr>
      <div class="d-flex">
        <div class="ml-auto">
          <a-button
            type="primary"
            @click="handleSubmit"
            :loading="submitState.loading"
            :disabled="submitDisabled"
          >
            Edit
          </a-button>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import apiClient from '@/services/axios'
// import store from 'store'
// import accessMix from '@/services/accessmix'

export default {
  name: 'editModal',
  props: ['editableRefferal'],
  computed: {
    submitDisabled() {
      return !(this.form.name.length > 0 && this.form.bundle.length > 0 && this.form.promocode.length > 0)
    },
  },
  data() {
    return {
      submitState: {
        loading: false,
        disabled: true,
      },
      form: { ...this.editableRefferal },
      rules: {
        rules: {
          bundle: [{ required: true, message: 'Please input bundle', trigger: 'change' }],
          name: [{ required: true, message: 'Please input name', trigger: 'change' }],
          promocode: [{ required: true, message: 'Please input promocode', trigger: 'change' }],
        },
      },
    }
  },
  mounted () {
    // ---
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.$refs.editModalForm.validate(valid => {
        if (valid) {
          try {
            this.submitState.loading = true
            this.editBundle()
          } catch (e) {
            console.log(e)
          } finally {
            this.submitState.loading = false
            this.$emit('refferalWasEdited')
            this.$emit('closeModalFunction')
          }
        } else {
          return false
        }
      })
    },
    async editBundle() {
      const url = `/admin/refferal/${this.form.id}`
      await apiClient.post(url, this.form)
    },
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(obj) {
      this.$emit('createAccount', obj)
    },
  },
}
</script>

<style lang="scss">
.create-refferal-modal {
  // button {
  //   &.disable {
  //     cursor: none;
  //     background-color: red;
  //   }
  // }
}
</style>

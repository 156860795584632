<template>
  <div class="users-content">
    <a-row>
      <a-col :span="18">
        <h3>Refferal promocodes</h3>
      </a-col>
      <a-col :span="6" class="text-right">
        <a-button type="primary" @click="openedRefferalModal = true">
          <a-icon type="plus" /> New bundle ID
        </a-button>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <a-table
        :columns="columns"
        :pagination="pagination"
        :loading="loading"
        :data-source="refferals"
        :rowKey="(_, index) => index"
        @change="tableChange"
      >
        <template slot="operation" slot-scope="text, record">
          <div class="row-operations">
            <span>
              <a class="font-size-18 text-primary" href="javascript:;" @click="() => editRefferal(record)"><a-icon type="edit" /></a>
            </span>
            <span>
              <a-popconfirm
                title="Sure to delete this refferal?"
                @confirm="() => onDelete(record.id)"
              >
                <a class="font-size-18 text-danger" href="javascript:;"><a-icon type="delete" /></a>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal
      v-model="openedRefferalModal"
      title="Create new bundle"
      :footer="null"
      class="account-modal"
      width="425px"
      :destroyOnClose="true"
    >
      <create-modal
        @createAccount="handleCreateRefferal"
        @closeModalFunction="openedRefferalModal = false"
        @refferalWasCreated="refferalWasCreated"
        ref="createRefferalModal"
      />
    </a-modal>
    <a-modal
      v-model="editedRefferalModal"
      title="Edit bundle"
      :footer="null"
      class="account-modal"
      width="425px"
      :destroyOnClose="true"
    >
      <edit-modal
        :editableRefferal="editableRefferal"
        @createAccount="handleCreateRefferal"
        @closeModalFunction="editedRefferalModal = false"
        @refferalWasEdited="refferalWasEdited"
        ref="editRefferalModal"
      />
    </a-modal>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import createModal from './createModal.vue'
import editModal from './editModal.vue'

export default {
  name: 'index',
  components: {
    createModal,
    editModal,
  },
  mixins: [accessMix],
  computed: {

  },
  data() {
    return {
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Promocode',
          dataIndex: 'promocode',
          key: 'promocode',
          scopedSlots: { customRender: 'promocode' },
        },
        {
          title: 'Bundle',
          dataIndex: 'bundle',
          key: 'bundle',
          scopedSlots: { customRender: 'bundle' },
        },
        {
          title: 'Friendly name',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Actions',
          dataIndex: 'operation',
          key: 'operation',
          align: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      pagination: {
        current: 1,
        items: 10,
        total: 0,
        pageSize: 10,
      },
      editableRefferal: null,
      loading: false,
      refferals: [],
      openedRefferalModal: false,
      editedRefferalModal: false,
    }
  },
  mounted () {
    this.fetchRefferalPromocodes(true)
  },
  methods: {
    async onDelete(key) {
      try {
        this.loading = true
        const url = `/admin/refferal/${key}`
        await apiClient.delete(url)
        await this.fetchRefferalPromocodes()

        // TODO
        if (this.refferals.length === 0 && this.pagination.current !== 1) {
          this.pagination.current -= 1
          await this.fetchRefferalPromocodes()
        }
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    editRefferal(obj) {
      this.editableRefferal = obj
      this.editedRefferalModal = true
    },
    refferalWasCreated() {
      this.loading = true
      setTimeout(() => {
        this.fetchRefferalPromocodes()
        this.loading = false
      }, 300)
    },
    refferalWasEdited() {
      this.loading = true
      setTimeout(() => {
        this.fetchRefferalPromocodes()
        this.loading = false
      }, 300)
    },
    handleCreateRefferal() {

    },
    handleCancelCreateRefferal() {

    },
    tableChange(e) {
      this.pagination.current = e.current
      this.fetchRefferalPromocodes(true)
    },
    async fetchRefferalPromocodes(loading = false) {
      try {
        if (loading) this.loading = true
        const url = `/admin/refferal?items=${this.pagination.items}&page=${this.pagination.current}`
        const refferals = await apiClient.get(url)
        this.refferals = refferals.data.data
        // this.pagination.pageSize = refferals.data.meta.pages
        this.pagination.total = refferals.data.meta.total
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
